import { TFunction } from "i18next";
import { getBaseUrl } from '../../configVariables';


export const getInitAdaptiveCard = (t: TFunction, Type: number) => {
    const titleTextAsString = t("TitleText");
    const summary = t("Summary");
    switch (Type) {
        case 0:
            {
                return (
                    {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/Logo.png",
                                "size": "Large",
                                "horizontalAlignment": "Center",
                                "height": "80px"
                            },
                            {
                                "type": "TextBlock",
                                "weight": "Bolder",
                                "spacing": "None",
                                "text": titleTextAsString,
                                "size": "ExtraLarge",
                                "wrap": true,
                                "horizontalAlignment": "Center"
                            },
                            {
                                "type": "Image",
                                "size": "Stretch",
                                "width": "400px"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "horizontalAlignment": "Left"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter"
                            },
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/FDF-Banner.png",
                                "size": "Stretch",
                                "spacing": "Small",
                                "horizontalAlignment": "Center"
                            }
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.4"
                    }
                );
                break;
            }
        case 2:
            {
                return (
                    {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                                "type": "Image",
                                "width": "400px",
                                "size": "Stretch",
                                "spacing":"Default",
                                "url": getBaseUrl()+"/image/banner.png",
                                "selectAction": {
                                    "type": "Action.OpenUrl",
                                    "url": "https://www.blueridgeit.com"
                                }
                            }
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.2"
                    }
                );
            }
        case 1:
            {
                return (
                    {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/Logo.png",
                                "size": "Large",
                                "horizontalAlignment": "Center",
                                "height": "80px"
                            },
                            {
                                "type": "Media",
                                "poster": "${ThumbnailUrl}",
                                "sources": [
                                    {
                                        "mimeType": "video/mp4",
                                        "url": "${Url}"
                                    }
                                ]
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "horizontalAlignment": "Left"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter"
                            },
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/FDF-Banner.png",
                                "size": "Stretch",
                                "spacing": "Small",
                                "horizontalAlignment": "Center"
                            }
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.4"
                    }
                );
                      
            }
        case 3:
            {
                return (
                    {
                        "type": "AdaptiveCard",
                        "body": [
                            
                            {
                                "type": "Media",
                                "poster": "${ThumbnailUrl}",
                                "sources": [
                                    {
                                        "mimeType": "video/mp4",
                                        "url": "${Url}"
                                    }
                                ]
                            }
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.2"
                    }
                );
            }
        case 4:
            {
                return (
                    {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/Logo.png",
                                "size": "Large",
                                "horizontalAlignment": "Center",
                                "height": "80px"
                            },
                            {
                                "type": "TextBlock",
                                "weight": "Bolder",
                                "spacing": "None",
                                "text": titleTextAsString,
                                "size": "ExtraLarge",
                                "wrap": true,
                                "horizontalAlignment": "Center"
                            },
                            {
                                "type": "Image",
                                "size": "Stretch",
                                "width": "400px"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "horizontalAlignment": "Left"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter",
                                "horizontalAlignment": "Left"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter",
                                "horizontalAlignment": "Left"
                            },
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/FDF-Banner.png",
                                "size": "Stretch",
                                "spacing": "Small",
                                "horizontalAlignment": "Center"
                            }
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.5",
                        "rtl": "true",
                        "lang": "AR"
                    }
                );
                
            }
        case 5:
            {
                return (
                    {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/Logo.png",
                                "size": "Large",
                                "horizontalAlignment": "Center",
                                "height": "80px"
                            },
                            {
                                "type": "TextBlock",
                                "weight": "Bolder",
                                "spacing": "None",
                                "text": titleTextAsString,
                                "size": "ExtraLarge",
                                "wrap": true,
                                "horizontalAlignment": "Center"
                            },
                            {
                                "type": "Media",
                                "poster": "${ThumbnailUrl}",
                                "sources": [
                                    {
                                        "mimeType": "video/mp4",
                                        "url": "${Url}"
                                    }
                                ]
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "horizontalAlignment": "Left"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter"
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "size": "Small",
                                "weight": "Lighter"
                            },
                            {
                                "type": "Image",
                                "url": getBaseUrl() + "/image/FDF-Banner.png",
                                "size": "Stretch",
                                "spacing": "Small",
                                "horizontalAlignment": "Center"
                            }
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.5",
                        "rtl": "true",
                        "lang":"AR"
                    }
                );

            }
        case 6:
            {
                return (
                    {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                            "type": "ColumnSet",
                            "columns": [
                                {
                                    "type": "Column",
                                    "width": "stretch",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "size": "Medium",
                                            "weight": "Bolder",
                                            "spacing": "None",
                                            "isSubtle": false,
                                            "text":  "خمسون عاماً على الاتحاد"
                                        }
                                    ]
                                },
                                {
                                    "type": "Column",
                                    "width": "stretch",
                                    "items": [
                                        {
                                            "type": "Image",
                                            "url": getBaseUrl() + "/image/UAE-50 Logo.png",
                                            "size": "Large",
                                            "spacing": "None",
                                            "horizontalAlignment": "Right"
                                        }
                                    ],
                                    "bleed": true,
                                    "spacing": "None"
                                }
                            ],
                            "bleed": true
        },
                    {
                        "type": "Image",
                        "url": getBaseUrl() + "/image/CompanyLogo.png",
                        "spacing": "None"
                    },
                    {
                        "type": "TextBlock",
                        "wrap": true,
                        "text": summary
                    },
                    {
                        "type": "TextBlock",
                        "wrap": true,
                        "horizontalAlignment": "Right",
                        "weight": "Bolder",
                        "text": " "
                    },
                    {
                        "type": "Image",
                        "url": getBaseUrl() + "/image/UAE-50-Banner.png",
                        "size": "Stretch",
                        "width": "400px",
                        "horizontalAlignment": "Center"
                    }
    ],
"$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
    "version": "1.4",
        "rtl": true
}
                );

            }
    }
}

export const getCardTitle = (card: any) => {
    return card.body[1].text;
}
export const setCardPosterAction = (card: any, action?: string)=> {
    card.body[0].selectAction.url = action;
}

export const setCardTitle = (card: any, title: string) => {
    card.body[1].text = title;
}
export const setCardPosterUrl = (card: any, imageLink?: string) => {
    card.body[0].url = imageLink;
}

export const getCardImageLink = (card: any) => {
    return card.body[2].url;
}
export const setCardDepartment = (card: any, department?: string) => {
    card.body[4].text = department;
}
export const setCardImageLink = (card: any, imageLink?: string) => {
    card.body[2].url = imageLink;
}
export const setCardPosterLink = (card: any, imageLink?: string) => {
    card.body[2].poster = imageLink;
}
export const setCardVideoLink = (card: any, videoLink?: string) => {
    card.body[2].sources[0].url = videoLink;
}
export const setCardVideoPlayerUrl = (card: any, videoLink?: string) => {
    card.body[0].sources[0].url = videoLink;
}
export const setCardVideoPlayerPoster = (card: any, imageLink?: string) => {
    card.body[0].poster = imageLink;
}
export const getCardSummary = (card: any) => {
    return card.body[3].text;
}

export const setCardSummary = (card: any, summary?: string) => {
    card.body[3].text = summary;
}

export const getCardAuthor = (card: any) => {
    return card.body[5].text;
}

export const setCardAuthor = (card: any, author?: string) => {
    card.body[5].text = author;
}

export const getCardBtnTitle = (card: any) => {
    return card.actions[0].title;
}

export const getCardBtnLink = (card: any) => {
    return card.actions[0].url;
}
export const setUAE50Summary = (card: any, summary?: string) => {
    card.body[2].text = summary;
}
export const setUAE50Author = (card: any, author?: string) => {
    card.body[3].text = author;
}
export const getUAE50Summary = (card: any,) => {
    return card.body[2].text;
}
export const getUAE50Author = (card: any,) => {
    return card.body[3].text ;
}
export const setCardBtn = (card: any, buttonTitle?: string, buttonLink?: string) => {
    if (buttonTitle && buttonLink) {
        //buttonTitle.forEach(function () {
        //    card.actions
        //})
        card.actions = [
            {
                "type": "Action.OpenUrl",
                "title": buttonTitle,
                "url": buttonLink
            }
        ];
    } else {
        delete card.actions;
    }
}
